<template>
  <div>
    <v-form @submit.prevent="onBeforeSubmit" ref="formRef">

      <v-row dense class="pt-4 pb-0 mb-0">
          <div class="center-text h1"> Taxa: {{currentItem.taxa.taxa.nome}} {{ currentItem.taxa.nomecomercial ? ` - "${currentItem.taxa.nomecomercial}  "` : '' }} - Cód.: {{currentItem.taxa.codigo}}  - Id: {{currentItem.id}}
          </div>
      </v-row>
      <v-row dense>
        <v-col>
          <v-select outlined required label="Status" v-model="currentItem.ativo" item-value="value"
            item-text="nome" :items="ativoOptions"></v-select>
        </v-col> 
        <v-col v-if="currentItem.aditivo_taxa">
          <v-select outlined required label="Aditivo" v-model="currentItem.aditivo_taxa" item-value="value"
            item-text="nome" :items="statusOptions" disabled></v-select>
        </v-col> 
        <v-col  v-if="currentItem.aditivo_taxa">
          <v-text-field label="Nr do Aditivo" outlined v-model="currentItem.nr_aditivo" type="Number"  disabled> </v-text-field>
        </v-col>
        <v-col  v-if="currentItem.aditivo_taxa">
          <v-text-field label="Data do Aditivo" outlined v-model="currentItem.data_aditivo" type="date"  disabled> </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Data de Início" outlined v-model="formattedDataInicio" type="date" > </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Data de Término" outlined v-model="formattedDataFim" type="date" > </v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-text-field label="Quantidade" outlined v-model="currentItem.quantidade_taxa" type="Number" >
          </v-text-field>
        </v-col> 
        <v-col>
          <vuetify-money outlined label="Valor cobrança" v-model="currentItem.valor_cobranca_taxa" :options="options" disabled>
          </vuetify-money>
        </v-col>
        <v-col>
          <v-text-field label="Desconto (%)" outlined v-model="currentItem.desconto_taxa" >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Acréscimo (%)" outlined v-model="currentItem.acrescimo_taxa" >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Total" outlined v-model="procedureTotalValue" disabled></v-text-field>
        </v-col>
        <v-col >
          <v-select outlined required label="Recurso Glosa" v-model="currentItem.recurso_glosa_taxa" item-value="value"
            item-text="nome" :items="statusOptions"></v-select>
        </v-col>
      </v-row>
      <v-row dense >
        <v-col v-if="currentItem.recurso_glosa_taxa && currentItem.sessao.realizarrecursoglosa" cols="4">
          <v-autocomplete 
          outlined 
          label="Código do Recurso de Glosa" 
          v-model="currentItem.motivoglosa_taxa" 
          :items="codigorecurso" 
          item-value="id" 
          :item-text="(item) => `${item.codigo} - ${item.nome.replace(/\n/g, ' ')}`" 
          @change="updateMotivoGlosa"
          ></v-autocomplete>
        </v-col>
        <v-col v-if="currentItem.recurso_glosa_taxa && currentItem.sessao.realizarrecursoglosa" cols="8">
          <v-text-field label="Justificativa da Glosa " outlined v-model="currentItem.justificativarecursoglosa_taxa"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-end">
          <ConfirmButton color="success" :loading="loading">
            Salvar
          </ConfirmButton>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
  
<script>
import ConfirmButton from '../../ConfirmButton.vue';
import api from "../../../http";
import '../../../assets/css/styles.css';



export default {
  name: 'ProcedureFormTaxa',
  components: { ConfirmButton },
  props: {
    onSubmit: Function,
    currentItem: Object,
    statusOptions: Array,
    ativoOptions: Array,
    loading: Boolean
  },
  data() {
    return {
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2
    },
    codigorecurso: [], // Aqui está agora no nível certo para ser reativo
    selectedRecursoGlosa: null,
    requiredField: [e => e !== undefined  && e !== null && e!== '' || 'Obrigatório'],
  }},
  methods: {
    onBeforeSubmit() {
      const validation = this.$refs.formRef.validate()
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }
       // Função para substituir vírgula por ponto e garantir duas casas decimais
      const formatNumber = (value) => {
      if (value === null || value === undefined || value === '') {
        return 0;
      }
      if (typeof value === 'string') {
        value = value.replace(',', '.');
        return parseFloat(value).toFixed(2);
      }
      return value;
    };

      // Formatar os valores de desconto e acréscimo
      const desconto = formatNumber(this.currentItem.desconto_taxa);
      const acrescimo = formatNumber(this.currentItem.acrescimo_taxa);
      
      let valorCobrancaFinal = this.procedureTotalValue;

      // Verificar se valorCobrancaFinal é uma string antes de chamar replace
      if (typeof valorCobrancaFinal === 'string') {
        valorCobrancaFinal = parseFloat(valorCobrancaFinal.replace('R$', '').replace('.', '').replace(',', '.'));
      } else if (typeof valorCobrancaFinal === 'number') {
        valorCobrancaFinal = parseFloat(valorCobrancaFinal.toFixed(2)); // Certificar-se de que é um número com 2 casas decimais
      } else {
        valorCobrancaFinal = 0; // Definir um valor padrão em caso de tipo inesperado
      }

      const fields = {
        id: this.currentItem.id,
        ativo: Boolean(this.currentItem.ativo),
        data_inicio_taxa: (this.currentItem.data_inicio_taxa),
        data_fim_taxa: (this.currentItem.data_fim_taxa),
        quantidade_taxa: Number(this.currentItem.quantidade_taxa),
        desconto_taxa: desconto,
        acrescimo_taxa: acrescimo,
        valor_cobranca_final_taxa: valorCobrancaFinal,
        motivoglosa_taxa: this.currentItem.motivoglosa_taxa,
        recurso_glosa_taxa: Boolean(this.currentItem.recurso_glosa_taxa),
        justificativarecursoglosa_taxa: this.currentItem.justificativarecursoglosa_taxa,
      }
      if (this.currentItem.aditivo_taxa) {
        fields.nr_aditivo = this.currentItem.nr_aditivo;
        fields.data_aditivo = this.currentItem.data_aditivo;
      }

      // console.log(fields)
      this.onSubmit(fields)
    },
    calculateQuantity() {
        const startDate = new Date(this.currentItem.data_inicio_taxa.split('T')[0]);
        const endDate = new Date(this.currentItem.data_fim_taxa.split('T')[0]);
        if (!isNaN(startDate) && !isNaN(endDate)) {
            // Calcula a diferença em dias entre as duas datas
            const timeDiff = endDate.getTime() - startDate.getTime();
            const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
            this.currentItem.quantidade_taxa = diffDays;
        } else {
            this.currentItem.quantidade_taxa = 0;
        }
    },
     async getCodigoRecurso() {
      try {
        const { data } = await api.get('uteis/motivoglosa/');
        this.codigorecurso = data.filter(item => item.ativo);
        // console.log('Código Recurso:', this.codigorecurso); // Verifique os dados aqui
      } catch (error) {
        alert("Erro ao listar os recursos de glosa");
        console.log(error);
      }
     },
     updateMotivoGlosa(selectedId) {
      // Encontra o item selecionado pelo ID
      const selectedRecurso = this.codigorecurso.find(item => item.id === selectedId);
      
      // Atualiza o campo motivoglosa com o nome do recurso de glosa selecionado
      if (selectedRecurso) {
        this.currentItem.motivoglosa_taxa = selectedRecurso.id;
        // console.log('Motivo da Glosa Atualizado:', this.currentItem.motivoglosa);
      }
    },
  },
  computed: {
    formattedDataInicio: {
      get() {
        return this.currentItem.data_inicio_taxa ? this.currentItem.data_inicio_taxa.split('T')[0] : '';
      },
      set(newValue) {
        this.currentItem.data_inicio_taxa = newValue;
      }
    },
    formattedDataFim: {
      get() {
        return this.currentItem.data_fim_taxa ? this.currentItem.data_fim_taxa.split('T')[0] : '';
      },
      set(newValue) {
        this.currentItem.data_fim_taxa = newValue;
      }
    },
    procedureTotalValue() {
     const basePrice = parseFloat(this.currentItem.valor_cobranca_taxa);
      const discount = this.currentItem.desconto_taxa ? parseFloat(this.currentItem.desconto_taxa) / 100 : 0;
      const addition = this.currentItem.acrescimo_taxa ? parseFloat(this.currentItem.acrescimo_taxa) / 100 : 0;
      const adjustedPrice = (basePrice * (1 + addition - discount)) * this.currentItem.quantidade_taxa;
      return `R$ ${adjustedPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    }
  },
   watch: {
    'currentItem.data_inicio_taxa': 'calculateQuantity',
    'currentItem.data_fim_taxa': 'calculateQuantity'
  },
   mounted() {
    this.getCodigoRecurso();
  }
}
</script>
  
<style scoped>
.center-text {
  text-align: center;
  font-size: 20px;
}
</style>
